export const theme = {
  breakpoints: {
    s: 'only screen and (max-width: 65rem)',
    m: 'only screen and (max-width: 70rem)',
  },
  //   fontFamily: "Roboto, Helvetica, Arial, sans-serif", //Capita
  /*
    Use the following site to find the name of the nearest color
    http://chir.ag/projects/name-that-color

    If color with such name already present in theme use "light-" and "dark-" prefixes
  */

  colors: {
    white: '#fff',
    black: '#000',
    nero: '#2b2b2b',
    grey: '#777',
    riverBed: '#4a5568', // text-gray-700
    nightRider: '#333',
    charcoal: '#444',
    mediumRedViolet: '#a72c8e',
    darkViolet: '#4B204E',
    mediumRedVioletLight: '#c233a5',
    carissma: '#eb89ba', // $color-light-pink
    carissma2: '#80164d', // $color-dark-mauve
    alabaster: '#f7f7f7', // $color_grey_light-1
    gallery: '#eee', // $color_grey_light-2

    emerald: '#55c57a', // $color-green
    pastelGreen: '#73d56f', // $color-green-light
    jungleGreen: '#28b485', //$color-green-dark
    borderGreen600: '#38a169',

    selectiveYellow: '#ffb900', // $color-secondary-light
    burningOrange: '#ff7730', // $color-secondary-dark
    borderRed600: '#e53e3e',

    dodgerBlue: '#2998ff', // $color-tertiary-light
    royalBlue: '#5643fa', // $color-tertiary-dark
  },

  gradient: {
    orange: `linear-gradient(to right bottom, rgba(255, 185, 0, 0.85),rgba(255, 119, 48, 0.85)`,
  },

  primary: '#55c57a',
  secondary: '#5643fa',
  white: '#fff',
};
