import React from 'react';
import loadable from '@loadable/component'
import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';

const Layout = loadable(() => import('../components/Layout'))
const SuccessComponent = loadable(() => import('../components/success/success-main'))
const Head = loadable(() => import('../components/Head'))



const Success = () => {

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Head title="Thank you" />
                <SuccessComponent/>                
            </Layout>

        </ThemeProvider>
    );
};

export default Success;